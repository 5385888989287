import React from 'react';

const TimelineItem = ({ icon, name, url, date, active }) => {
  const meses = [
    'jan',
    'fev',
    'mar',
    'abr',
    'mai',
    'jun',
    'jul',
    'ago',
    'set',
    'out',
    'nov',
    'dez',
  ];
  let data = new Date(date);
  let dataFormatada = meses[data.getMonth()] + '/' + data.getFullYear();

  return (
    <div className="timeline-item">
      {icon ? icon : null}
      <div className="timeline-item_divisor">
        <div className="timeline-item_line" />
        <div
          className={
            active ? 'timeline-item_circle-active' : 'timeline-item_circle'
          }
        />
      </div>
      <div className="timeline-item_description">
        {name ? (
          <a
            href={url}
            target="_blank"
            className={active ? 'timeline-item_description-active' : null}
            rel="noreferrer"
          >
            {name}
          </a>
        ) : null}
        {date ? <span>{dataFormatada}</span> : null}
      </div>
    </div>
  );
};

export default TimelineItem;
