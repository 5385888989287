import { Document } from 'iconsax-react';
import { Button } from '../../../../../ds-components';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

const CiteButton = ({ cards, ...props }) => {
  const { pathname } = useLocation();
  const isInMonitoring = useMemo(
    () => pathname.includes('/monitoramento'),
    [pathname],
  );

  const copyCitationToClipboard = (cards) => {
    let citeText = '';

    cards.forEach((card) => {
      const div = document.createElement('div');
      div.innerHTML = card.content[0].content;
      const ementa = div.innerText;

      const cardInformation =
        `\n\n${card.name}, ` +
        `${card.entity ? 'Órgão: ' + card.entity.name : null}, ` +
        `${card.relator ? 'Relator: ' + card.relator.name : null}, ` +
        `${card.dataJul ? card.dataJul : null}, ` +
        `${card.dataPub ? card.dataPub : null}.`;

      if (citeText.length > 0)
        citeText += '\n\n---------------------------------------------\n\n';
      citeText += ementa + cardInformation;
    });

    navigator.clipboard.writeText(citeText);

    toast.success('Copiado para a área de transferência', {
      icon: <Document size={24} color="#12B76A" />,
    });
  };

  return (
    <Button
      appearance={props.appearance}
      size={props.size}
      onClick={() => copyCitationToClipboard(cards)}
      className="citar-tour"
      iconPosition="left"
      icon={<Document size={16} color="#98A2B3" />}
      tooltipText="Copiar a citação"
    >
      {isInMonitoring ? '' : 'Citar'}
    </Button>
  );
};

export default CiteButton;
