import React from 'react';
import Timeline from '../../../../../core/components/Timeline/Timeline';
import TimelineItem from '../../../../../core/components/Timeline/TimelineItem';

import { DocumentText1 } from 'iconsax-react';

const DecisionsTimeline = ({ timeline }) => {
  return (
    <div className="timeline-decision">
      <p className="font-semibold">Veja outros acórdãos deste processo</p>
      <div className="timeline-decision_content mt-4">
        <Timeline>
          {timeline.complete_timeline?.map((item, index) => {
            return (
              <TimelineItem
                key={index}
                icon={
                  index === timeline.acordao_position ? (
                    <DocumentText1
                      size={40}
                      color="#053AC8"
                      variant="TwoTone"
                    />
                  ) : (
                    <DocumentText1 size={24} color="#84CAFF" />
                  )
                }
                name={item.n_acordao}
                date={item.date}
                url={item.url}
                active={index === timeline.acordao_position}
              />
            );
          })}
        </Timeline>
      </div>
    </div>
  );
};

export default DecisionsTimeline;
